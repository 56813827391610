import React, { type ReactNode, useReducer } from 'react';

import classnames from 'classnames';
import isNumber from 'lodash/isNumber';
import { Link } from 'react-router-dom';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Tooltip as TippyTooltip } from 'react-tippy';

import {
  NavigationInsightTopicsIcon as TopicIcon,
  FeatureTopicThemeBadgeIcon as SemanticTopicIcon,
} from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { BodyText, Heading5 } from '@peakon/bedrock/react/typography';
import { type Category, type Comment as CommentRecord } from '@peakon/records';
import { AsyncButton } from '@peakon/shared/components/AsyncButton/AsyncButton';
import { DateLabel } from '@peakon/shared/components/DateLabel';
import { Flex } from '@peakon/shared/components/Flex/Flex';
import { useHierarchyLevel } from '@peakon/shared/components/HierarchySection';
import { ScoreNumber } from '@peakon/shared/components/ScoreNumber';
import { type AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { analytics } from '@peakon/shared/features/analytics/analytics';
import {
  getCurrentLocaleInfo,
  getEmployeeScopedLocaleInfo,
} from '@peakon/shared/features/i18next/helpers';
import { validateLocaleId } from '@peakon/shared/features/i18next/localesValidation';
import { t } from '@peakon/shared/features/i18next/t';
import { getTranslatedLocaleNamesMap } from '@peakon/shared/features/i18next/translatedLocaleNames';

import { TranslatedByGoogle } from './assets/TranslatedByGoogle';
import { CommentDriverLabel } from './CommentDriverLabel';
import { CommentDropdown } from './CommentDropdown';
import { CommentManagers } from './CommentManagers';
import { CommentScore } from './CommentScore';
import { CommentTextIcon } from './CommentTextIcon';
import { HighlightBanner } from './HighlightBanner';
import { HighlightedCommentText } from './HighlightedCommentText';
import { SensitiveBanner } from './SensitiveBanner';
import { TopicBadge } from './TopicBadge';
import AcknowledgementCounts from '../acknowledgement/AcknowledgementCounts';
import AcknowledgementToggler from '../acknowledgement/AcknowledgementToggler';
import ConversationToggler from '../ConversationToggler';
import { Badge } from '../ValueBadge';

import styles from './styles.css';

function CommentText({
  children,
  lang,
}: {
  children: ReactNode;
  lang?: string | null;
}) {
  return (
    <BodyText data-test-id="comment-text" lang={lang || undefined}>
      {children}
    </BodyText>
  );
}

function CommentDate({ date, isRound }: { date: string; isRound: boolean }) {
  return isRound ? (
    <TippyTooltip
      animateFill={false}
      animation="shift"
      arrow
      delay={[100, 200]}
      inertia
      popperOptions={{
        modifiers: {
          keepTogether: {
            enabled: true,
          },

          preventOverflow: { boundariesElement: 'viewport' },
        },
      }}
      position="bottom"
      title={t('comments__round_close_date')}
    >
      <BodyText as="div" bold size="small" variant="hint">
        <DateLabel date={date} />
      </BodyText>
    </TippyTooltip>
  ) : (
    <BodyText as="div" bold size="small" variant="hint">
      <DateLabel date={date} />
    </BodyText>
  );
}

function negate(value: boolean) {
  return !value;
}

function useToggle(defaultValue = false) {
  return useReducer(negate, defaultValue);
}

export type Props = {
  comment: CommentRecord;
  category?: Category;

  /**
   *
   * NOTE: Making sure we pass in the `commentDateVisibility` when using the component directly (only one place at the time of writing this)
   *
   * This has always been optional, but that seems to be more of a "make sure it doesn't blow up" and accepting the fact that types aren't sound.
   * We should never really ignore the actual company setting.
   *
   * TODO: Consolidate this into the component, so we never have to pass this in, when we have a clear view of where what should live
   *
   */

  commentDateVisibility: 'date' | 'round' | undefined;
  contextId?: string;
  segmentId?: string;
  hideSensitiveHighlight?: boolean;
  acknowledgementCounts?: Partial<Record<AcknowledgementType, number>>;
  onAcknowledge?: (
    type: AcknowledgementType,
    opts: { optOutConfirm?: boolean },
  ) => Promise<void>;
  onConversationsClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: () => void;
  onLoadManagers?: () => void;
  isLoadingManagers?: boolean;
  onMarkAsSensitive?: () => void;
  onRevertTranslate?: (id: string) => void;
  onTranslate?: (id: string) => void;
  feedback?: ReactNode;
  isDisabled?: boolean;
  shouldConfirmAcknowledgement?: boolean;
  topics?: Array<{ id: string; name: string }>;
  semanticTopics?: Array<{ id: string; name: string; locale: string }>;
  topicHighlights?: Array<string>;
  translatable?: boolean;
  translated?: boolean;
  withHighlightBanner?: boolean;
  hasNewTranslations?: boolean;
  locale?: { id: string; language: string };
};

export function Comment({
  commentDateVisibility = 'date',
  translatable = false,
  withHighlightBanner = true,
  comment,
  semanticTopics,
  translated,
  topics,
  topicHighlights,
  hideSensitiveHighlight,
  contextId,
  onLoadManagers,
  isLoadingManagers,
  acknowledgementCounts,

  category,
  segmentId,
  onTranslate,
  onRevertTranslate,

  feedback,
  isDisabled,
  onAcknowledge,
  onConversationsClick,
  onDelete,
  onMarkAsSensitive,
  shouldConfirmAcknowledgement,
  hasNewTranslations,

  /**
   *
   * FIXME: Improve the types or fix the call sites
   *
   * We can improve the types to make sure the locale is always set when we pass `translatable`
   * but right now it is a bit tricky and our typescript version can't narrow destructured properties :/
   *
   * SO, we just create a fallback here to make sure it will never not be there
   *
   */

  locale = getCurrentLocaleInfo(),
}: Props) {
  const headingLevel = useHierarchyLevel(2);

  const [showCommentInOriginalLanguage, toggleShowCommentInOriginalLanguage] =
    useToggle(!comment.commentTranslation || comment.text.includes('**'));

  const translatedLocaleNamesMap = getTranslatedLocaleNamesMap();
  const targetLanguageName = translatedLocaleNamesMap.get(
    validateLocaleId(
      getEmployeeScopedLocaleInfo(
        comment.commentTranslation?.get('targetLocale') || locale.id,
      ).id,
    ),
  );
  const sourceLanguageName = comment.locale
    ? translatedLocaleNamesMap.get(
        validateLocaleId(getEmployeeScopedLocaleInfo(comment.locale).id),
      )
    : undefined;

  const sensitiveHighlight =
    !hideSensitiveHighlight &&
    comment.sensitive &&
    comment.sensitiveMatches &&
    !comment.sensitiveMatches.isEmpty();
  const searchHighlight = comment.text.includes('**');
  const isHighlighted = Boolean(
    topicHighlights?.length || sensitiveHighlight || searchHighlight,
  );

  const sensitiveMatches = comment.sensitiveMatches?.toJS();

  const nonTranslatedContent = isHighlighted ? (
    <HighlightedCommentText
      text={comment.text}
      topicMatches={topicHighlights}
      sensitiveMatches={
        /**
         *
         * TODO: Simplify checks
         *
         * The `!hideSensitiveHighlight` check we do in `sensitiveHighlight` has always been missing here, so to not introduce any unwanted changes we don't introduce it just yet
         *
         */

        comment.sensitive ? sensitiveMatches : undefined
      }
    />
  ) : (
    comment.text
  );

  const showSensitiveBanner = comment.sensitive;
  const highlightReasons = comment.highlightReasons;
  const showHighlightBanner =
    withHighlightBanner && comment.highlighted && highlightReasons;

  const isRound = commentDateVisibility === 'round';
  const date = isRound ? comment.closesAt : comment.answeredAt;

  const commentTranslationId = `comment-original-text-${comment.id}`;

  return (
    <div data-test-id="comment-container-with-feedback">
      <div
        data-test-id="comment-container"
        id={`comment-item-${comment.id}`}
        className={classnames(styles.container, {
          [styles.disabled]: isDisabled,
        })}
      >
        <Card>
          {showSensitiveBanner ? (
            <div className={styles.banner}>
              <SensitiveBanner matches={sensitiveMatches} />
            </div>
          ) : showHighlightBanner ? (
            <div className={styles.banner}>
              <HighlightBanner reasons={highlightReasons} />
            </div>
          ) : null}

          <div className={styles.header}>
            <div className={styles.iconArea}>
              {comment.type === 'text' ? (
                <CommentTextIcon />
              ) : (
                <CommentScore type={comment.type} score={comment.score} />
              )}
            </div>

            <div className={styles.questionArea}>
              <Heading5
                level={headingLevel}
                data-test-id="comment-question-title"
              >
                {comment.type === 'value' && category && (
                  <span>
                    <strong className={styles.questionValue}>
                      {category?.nameTranslated}
                    </strong>
                    &nbsp;-&nbsp;
                  </span>
                )}
                {comment.question.text}
              </Heading5>
            </div>

            <div className={styles.dropdownArea}>
              <CommentDropdown
                isSensitive={comment.sensitive}
                onDelete={onDelete}
                onMarkAsSensitive={onMarkAsSensitive}
              />
            </div>

            <div className={styles.infoArea}>
              <ul className={styles.info}>
                {date ? (
                  <li className={styles.infoItem}>
                    {isDisabled ? (
                      <CommentDate date={date} isRound={isRound} />
                    ) : (
                      <Link
                        className={styles.permalink}
                        to={`/dashboard/comments/${comment.id}${
                          contextId ? `?contextId=${contextId}` : ''
                        }`}
                      >
                        <CommentDate date={date} isRound={isRound} />
                      </Link>
                    )}
                  </li>
                ) : null}

                {isNumber(comment.score) && (
                  <li className={styles.infoItem}>
                    <BodyText as="div" bold variant="hint" size="small">
                      <span>{t('dashboard__input__score')}</span>:{' '}
                      <ScoreNumber
                        fontSize={12}
                        inline
                        score={comment.score.toFixed(0)}
                      />
                    </BodyText>
                  </li>
                )}

                {category && !category.isGroup('text') ? (
                  <li className={styles.infoItem}>
                    <Flex alignItems="center">
                      <CommentDriverLabel
                        category={category}
                        contextId={contextId}
                        segmentId={segmentId}
                      />
                      {category.isGroup('values') && <Badge />}
                    </Flex>
                  </li>
                ) : null}

                {onLoadManagers && !isDisabled && (
                  <li className={classnames(styles.infoItem, styles.manager)}>
                    <CommentManagers
                      isLoading={isLoadingManagers}
                      managers={comment.managers?.toJS()}
                      onLoad={onLoadManagers}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className={styles.content}>
            {!translatable ? (
              <CommentText lang={comment.locale}>
                {nonTranslatedContent}
              </CommentText>
            ) : hasNewTranslations ? (
              <div>
                <div className={styles.translation}>
                  <Flex gap={8} flexDirection="column">
                    {comment.commentTranslation ? (
                      <CommentText
                        lang={comment.commentTranslation.get('targetLocale')}
                      >
                        {comment.commentTranslation.get('text')}
                      </CommentText>
                    ) : translated ? (
                      <CommentText lang={locale.language}>
                        {comment.translation}
                      </CommentText>
                    ) : null}

                    <Flex gap={4} alignItems="center" flexWrap="wrap">
                      {comment.commentTranslation || translated ? (
                        <TranslatedByGoogle className={styles.googleIcon} />
                      ) : (
                        <BodyText variant="hint" size="small">
                          {t('dashboard__comment_card__source_language_text', {
                            replace: { sourceLanguageName },
                          })}
                        </BodyText>
                      )}

                      {comment.commentTranslation ? (
                        <Button
                          variant="tertiary"
                          size="small"
                          onClick={() => {
                            if (!showCommentInOriginalLanguage) {
                              analytics.track(
                                'comment_show_original_comment__clicked',
                              );
                            }
                            toggleShowCommentInOriginalLanguage();
                          }}
                          aria-controls={commentTranslationId}
                          aria-expanded={showCommentInOriginalLanguage}
                          // We should't be changing the contents of the button - the expanded state will do
                          aria-label={t(
                            'dashboard__comment_card__show_original_language__button_a11y_label',
                          )}
                        >
                          {showCommentInOriginalLanguage
                            ? t(
                                'dashboard__comment_card__hide_original_language__button',
                              )
                            : t(
                                'dashboard__comment_card__show_original_language__button',
                              )}
                        </Button>
                      ) : (
                        <AsyncButton
                          variant="tertiary"
                          size="small"
                          // @ts-expect-error - Type () => void is not assignable to type () => Promise<unknown>
                          onClick={
                            translated
                              ? () =>
                                  // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
                                  onRevertTranslate(comment.id)
                              : () =>
                                  // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
                                  onTranslate(comment.id)
                          }
                        >
                          {translated
                            ? t(
                                'dashboard__comment_card__hide_translation_button',
                              )
                            : t('dashboard__comment_card__translate_button', {
                                replace: {
                                  language: targetLanguageName,
                                },
                              })}
                        </AsyncButton>
                      )}
                    </Flex>
                  </Flex>
                </div>

                <div id={commentTranslationId}>
                  {showCommentInOriginalLanguage ? (
                    <div className={styles.expandableCommentContent}>
                      <CommentText lang={comment.locale}>
                        {nonTranslatedContent}
                      </CommentText>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <Flex flexDirection="column" gap={16}>
                <div className={styles.translate}>
                  <AsyncButton
                    size="small"
                    variant="secondary"
                    onClick={() =>
                      // @ts-expect-error - Type 'void' is not assignable to type 'Promise<unknown>'.
                      translated
                        ? // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
                          onRevertTranslate(comment.id)
                        : // @ts-expect-error - Cannot invoke an object which is possibly 'undefined'.
                          onTranslate(comment.id)
                    }
                  >
                    {translated
                      ? t('dashboard__original-btn', {
                          replace: { language: sourceLanguageName },
                        })
                      : t('dashboard__translate-btn', {
                          replace: { language: targetLanguageName },
                        })}
                  </AsyncButton>

                  <div className={styles.translateText}>
                    <BodyText size="small" variant="hint">
                      {translated
                        ? t('dashboard__original-text', {
                            replace: { language: targetLanguageName },
                          })
                        : t('dashboard__translate-text', {
                            replace: { language: sourceLanguageName },
                          })}
                    </BodyText>
                  </div>
                </div>

                <CommentText
                  lang={translated ? locale.language : comment.locale}
                >
                  {translated ? comment.translation : nonTranslatedContent}
                </CommentText>
              </Flex>
            )}

            {acknowledgementCounts ||
            topics?.length ||
            semanticTopics?.length ? (
              <Flex flexDirection="column" gap={16}>
                {acknowledgementCounts ? (
                  <AcknowledgementCounts counts={acknowledgementCounts} />
                ) : null}

                {topics?.length || semanticTopics?.length ? (
                  <Flex alignItems="center" gap={8} flexWrap="wrap">
                    {topics?.map((tag) => {
                      return (
                        <TopicBadge
                          key={tag.id}
                          icon={<TopicIcon />}
                          name={tag.name}
                          locale={comment.locale}
                          to={`/dashboard/topics/simple/overview/${tag.id}`}
                        />
                      );
                    })}

                    {semanticTopics?.map(
                      ({ id, name, locale: topicLocale }) => {
                        return (
                          <TopicBadge
                            key={`${id}-${name}`}
                            icon={<SemanticTopicIcon />}
                            name={name}
                            locale={topicLocale}
                            to={`/dashboard/topics/semantic/overview/${id}`}
                          />
                        );
                      },
                    )}
                  </Flex>
                ) : null}
              </Flex>
            ) : null}
          </div>

          {!isDisabled && (onAcknowledge || onConversationsClick) ? (
            <div className={styles.footer}>
              {onAcknowledge && (
                <AcknowledgementToggler
                  acknowledgement={comment.acknowledgement}
                  onAcknowledge={onAcknowledge}
                  shouldConfirm={shouldConfirmAcknowledgement}
                />
              )}

              {onConversationsClick && (
                <ConversationToggler
                  active={comment.active}
                  count={comment.messageCount}
                  onClick={onConversationsClick}
                />
              )}
            </div>
          ) : null}
        </Card>
      </div>

      {feedback}
    </div>
  );
}
